<template>
  <div class="propertyTpye">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="propertyTpye-back"
      @click="back()"
    />
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/1413a69cacb7ba1c1bc1348e58696642.png"
      alt=""
      class="propertyTpye-but"
    />
    <div class="propertyTpye-ul">
      <div
        class="flex flex-wrap"
        style="height: 100%; width: 100%; overflow: auto"
      >
        <div
          v-for="(item, index) in commodityList"
          :key="index"
          @click="listButton(item)"
        >
          <div
            v-if="item.isSell == 100 || item.isWantBuy == 100"
            class="propertyTpye-ul-li"
          >
            <div class="propertyTpye-ul-li-text">{{ item.name }}</div>
            <div class="propertyTpye-ul-li-imgbac">
              <el-image
                :src="item.img"
                alt=""
                class="propertyTpye-ul-li-img"
              ></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      commodityList: [],
      type: 0,
    };
  },
  mounted() {
    this.commodityList = JSON.parse(this.$route.query.data);
    this.type = this.$route.query.type;
  },

  methods: {
    listButton(item) {
      if (item.isSell == 100 && item.isWantBuy == 100) {
        this.$router.push({
          path: "/marketplace/property/bazaar",
          query: { categoryId: item.categoryId, type: this.type },
        });
      } else {
        this.$router.push({
          path: "/marketplace/skin/bazaar",
          query: { categoryId: item.categoryId, type: this.type },
        });
      }
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.propertyTpye::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.propertyTpye {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/marketplace/consignmentsales.png")
    no-repeat;
  background-size: 100% 100%;
  // position: relative;
  display: flex;
  justify-content: center;
  overflow: auto;
  padding: 50px 0;
  &-but {
    position: absolute;
    right: 20px;
    bottom: 0;
    width: 203px;
    height: 413px;
  }
  &-back:hover {
    cursor: pointer;
  }
  &-back {
    position: absolute;
    top: 163px;
    left: 90px;
    width: 72px;
    height: 72px;
  }
  &-ul::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &-ul {
    width: 1068px;
    height: 756.9px;
    // flex: 1;
    overflow: auto;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/A10_pop_i1231g_004.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 75px 50px;
    &-li:hover {
      cursor: pointer;
    }
    div::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-li {
      width: 220px;
      height: 303px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10428.png")
        no-repeat;
      background-size: 100% 100%;
      // margin-bottom: 20px;
      margin-right: 14px;
      padding-top: 35px;
      &-imgbac {
        width: 100%;
        height: 185.45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-img {
        width: 161px;
        height: 161px;
      }
      &-text {
        color: #ffffff;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
}
</style>